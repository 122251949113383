<template>
  <div class="home">
    <div class="header">
      <div class="val">
        hello, {{ user.name }}
        <img v-if="user.sex == 'male'" src="../assets/male.png" />
        <img v-else src="../assets/female.png" />
      </div>
      <call-phone icon="assets/service_white.png"/>
    </div>
    <div class="prompt">
      Welcome Back
    </div>
    <div class="home_tips">
      <span>{{ statusTips }}</span>
    </div>
    <div class="loan">
      <div class="box">
        <img class="bg" src="../assets/loan_img_1.png">
        <div class="val" v-if="user.applyStatus == 'WAIT'">
          <div class="title">Application amount</div>
          <div class="tips" v-if="user.applyAmount">
            ₦ {{ user.applyAmount | formatMoney }}
          </div>
          <div class="tips" v-else>
            ₦ ????
          </div>
        </div>
        <div class="val" v-else-if="user.applyStatus == 'REJECTED'">
          <div class="title">Next application date</div>
          <div class="tips">
            {{ user.nextApplyDate || '????' }}
          </div>
        </div>
        <div class="val" v-else-if="user.applyStatus == 'LOAN'">
          <div class="item">
            <div class="title">Loan amount</div>
            <div class="tips" v-if="user.applyAmount">
              ₦ {{ user.applyAmount | formatMoney }}
            </div>
            <div class="tips" v-else>
              ₦ ????
            </div>
          </div>
          <div class="item">
            <div class="title">Expected lending date</div>
            <div class="tips">
              {{ user.applyDate || '????' }}
            </div>
          </div>
        </div>
        <div class="val" v-else-if="user.applyStatus == 'NORMAL'">
          <div class="item">
            <div class="title">Repayment amount</div>
            <div class="tips" v-if="user.repayAmount">
              ₦ {{ user.repayAmount | formatMoney }}
            </div>
            <div class="tips" v-else>
              ₦ ????
            </div>
          </div>
          <div class="item">
            <div class="title">Repayment date</div>
            <div class="tips">
              {{ user.repayDate || '????' }}
            </div>
          </div>
        </div>
        <div class="val" v-else>
          <div class="title">Available amount</div>
          <div class="tips" v-if="user.maxViewAmount">
            ₦ {{ user.maxViewAmount | formatMoney }}
          </div>
          <div class="tips" v-else>
            ₦ ????
          </div>
        </div>
      </div>
      <div class="link">
        Get
        <span>instant loan</span>
        up to 
        <span v-if="brand.maximumAmount">₦{{ maximumAmount | formatMoney }}</span>
        <span v-else>₦600,000</span>
        with no collateral in just 
        <span>one click</span>
      </div>
      <div class="bottom">
        <mt-button size="large" @click="submit">{{
          submitTitle
        }}</mt-button>
      </div>
    </div>
    <div class="not_settle" v-if="user.notSettleCount">
      <div class="val">
        <div>
          {{ $t('loanLengthTips') }}
          <span>{{ user.notSettleCount }}</span>
        </div>
        <span class="more" @click="$router.push('/mine/repayRecord')">{{ $t('viewMore') }}</span>
      </div>
      <img src="../assets/loan_img_4.png">
    </div>
    <div class="flag">
      <div class="val">
        <div>
          {{ $t('upTo') }}
          <span>₦ 600.000</span>
        </div>
        <span>{{ $t('loanInfo') }}</span>
      </div>
      <img src="../assets/loan_img_3.png">
    </div>
    <div class="userRecomendation">
      {{ $t('userRecomendation') }}
    </div>
    <div class="inviteFriends">
      <div class="val">
        <span>{{ $t('inviteFriends') }}</span>
        <!-- <div class="btn" @click="$router.push('/mine/inviteFriends')">
          {{ $t('viewMore') }}
        </div> -->
      </div>
      <img src="../assets/loan_img_2.png">
    </div>
    <div class="box">
      <!-- <div class="step">
        <div class="title">{{ $t('homeStep1.title') }}</div>
        <div v-for="(item, index) in $t('homeStep1.list')" :key="index" class="item">
          <div class="index">{{ index + 1 }}</div>
          <div class="des">
            <div>{{ item.title }}</div>
            <div>{{ item.value }}</div>
          </div>
          <svg aria-hidden="true" class="icon">
            <use :xlink:href="`#${iconList[0][index]}`" />
          </svg>
        </div>
      </div> -->
      <!-- <div class="step">
        <div class="title">{{ $t('homeStep2.title') }}</div>
        <div v-for="(item, index) in $t('homeStep2.list')" :key="index" class="item">
          <svg aria-hidden="true" class="icon">
            <use :xlink:href="`#${iconList[1][index]}`" />
          </svg>
          <div class="des">
            <div>{{ item.title }}</div>
            <div>{{ item.value }}</div>
          </div>
        </div>
      </div> -->
      <!-- <div class="step">
        <div class="title">{{ $t('homeStep3.title') }}</div>
        <div v-for="(item, index) in $t('homeStep3.list')" :key="index" class="item">
          <svg aria-hidden="true" class="icon">
            <use :xlink:href="`#${iconList[2][index]}`" />
          </svg>
          <div class="des">
            <div>{{ item.title }}</div>
            <div>{{ item.value }}</div>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import { getUserInfo } from '../utils/api'
import callPhone from '@/components/callPhone.vue'
import { step } from '../utils/api'
import getLocation from '../utils/gps'
import branch from '../../node_modules/branch-sdk'
import Vue from 'vue'
import gtmPageView from '../utils/gtm.js'
export default {
  name: 'Home',
  components: {
    callPhone,
  },
  data() {
    return {
      statusTips: '',
      submitTitle: '',
      iconList: [
        [
          'iconcaozuoguocheng',
          'icon_shijian_xiaoshuai',
          'iconiconset02100'
        ], [
          'iconnationaarea',
          'iconnianling',
          'icongongzuo'
        ], [
          'iconjurassic_pass-others',
          'iconicon-shortcut',
          'iconkafei',
          'iconkuaisu_jiasu'
        ]
      ]
    }
  },
  computed: {
    ...mapState(["brand", "user"]),
  },
  watch: {
    'user.applyStatus': {
      handler(val) {
        switch (val) {
          case 'WAIT':
            this.statusTips = 'You have a loan under approving, which is generally completed within half an hour. Please wait patiently.';
            this.submitTitle = 'View Detail'
            break;
          case 'REJECTED':
            this.statusTips = `You have a loan rejected. Please apply again after ${this.user.intervalDays} days.`
            this.submitTitle = 'APPLY FOR LOAN'
            break;
          case 'LOAN':
            this.statusTips = `You have a loan in disbursement, please pay attention to your email and SMS or log in to APP to check the status.`
            this.submitTitle = 'View Detail'
            break;
          case 'NORMAL':
            this.statusTips = `You have a loan in repayment.`
            this.submitTitle = 'Repayment'
            break;
          case 'OVERDUE':
            this.statusTips = `You have a loan that is overdue, please repay it as soon as possible.`
            this.submitTitle = 'Repayment'
            break;
          case 'EMPTY':
            this.statusTips = ''
            this.submitTitle = 'Apply'
            break;
          default:
            this.statusTips = `You have a loan application, application progress (${this.user.currentStep}/${this.user.totalSteps})`
            this.submitTitle = 'Apply'
            break;
        }
      },
      immediate: true,
    }
  },
  created() {
    gtmPageView(this)
    this.init()
  },
  mounted() {
    this.BEHAVIOR_ADD({
      id: 'P00_C00'
    })
  },
  destroyed() {
    this.BEHAVIOR_ADD({
      id: 'P00_C99'
    })
    this.BEHAVIOR_SEND()
  },
  methods: {
    ...mapMutations(["SET_USER"]),
    ...mapMutations('behavior', ["BEHAVIOR_ADD"]),
    ...mapActions('behavior', ["BEHAVIOR_SEND"]),
    async init() {
      this.$indicator.open({
        spinnerType: "fading-circle",
      });
      await Promise.all([
        this.getUser(),
      ])
      this.$indicator.close();
    },
    submit() {
      switch (this.user.applyStatus) {
        case 'WAIT':
        case 'LOAN':
          // 订单详情
          this.$router.push({path: '/apply/done'})
          break
        case 'NORMAL':
        case 'OVERDUE':
        case 'REPAY':
          // 还款 到借款详情
          this.$router.push(`/apply/loanDetail?contractNo=${this.user.applyId}`)
          break
        default:
          if (this.user.continuedLoan == 'Y') {
            this.onReApply() // 续贷申请
            break
          }
          // SETTLE REJECTED CANCEL 首贷
          // this.onApplyPre() // 首贷申请
          break
      }
    },
    // 预申请
    async onApplyPre() {
      try {
        // 自定义事件
        let pixel = window.Vue.$route.query['pixel'] || localStorage['pixel']
        if (pixel) {
          Vue.analytics.fbq.event('01_FIRST_LOAN_APPLY', { content_name: '01_FIRST_LOAN_APPLY' })
        }
        // Google Analytics  FIRST_LOAN_APPLY
        if (localStorage['ga']) {
          window.gtag('event', 'FIRST_LOAN_APPLY')
        }
      } catch(e) {
        console.log(e)
      }
      if (this.user.applyStatus && this.user.currentStep) {
        switch (this.user.currentStep) {
          case 1:
            this.$router.push('/step/realnameauth')
            return;
          case 2:
            this.$router.push('/step/jobauth')
            return;
          case 3:
            this.$router.push('/step/relationshipauth')
            return;
          case 4:
            this.$router.push('/step/bankInfo')
            return;
          case 5:
            this.$router.push('/step/idcardauth')
            return;
          case 6:
            this.$router.push('/step/confirm')
            return;
          default:
            break;
        }
      }
      await this.$axios({
        method: "post",
        url: step,
        data: {
          deviceId: 'H5',
          phone: this.user.phone,
          idcard: this.user.idcard,
          gps: await getLocation(),
          currentStep: 1,
          totalSteps: 7,
        },
      })
        .then(async (e) => {
          if (e.status.code == "000") {
            this.SET_USER({
              ...this.user,
              ...e.body.session.user,
              applyId: e.body.applyId
            })
            this.$router.push('/step/realnameauth')
          } else {
            this.$toast(e.status.msg);
          }
        })
        .catch(() => {});
      this.$indicator.close();
      branch.logEvent('01_FIRST_LOAN_APPLY', { metadata: this.user.phone })
    },
    // 续贷申请
    async onReApply() {
      this.$indicator.open({
        spinnerType: "fading-circle",
      });
      try {
        // 自定义事件
        let pixel = window.Vue.$route.query['pixel'] || localStorage['pixel']
        if (pixel) {
          Vue.analytics.fbq.event('11_CONTINUED_LOAN_APPLY', {
            content_name: '11_CONTINUED_LOAN_APPLY'
          })
        }
        // Google Analytics  CONTINUED_LOAN_APPLY
        if (localStorage['ga']) {
          window.gtag('event', 'CONTINUED_LOAN_APPLY')
        }
      } catch(e) {
        console.log(e)
      }
      await this.$axios({
        method: "post",
        url: step,
        data: {
          deviceId: 'H5',
          phone: this.user.phone,
          idcard: this.user.idcard,
          gps: await getLocation(),
          currentStep: 1,
          totalSteps: 7,
          continuedLoan: 'Y'
        }
      })
        .then(async (e) => {
          if (e.status.code == "000") {
            this.SET_USER({
              ...this.user,
              name: e.body.name,
              applyId: e.body.applyId
            })
            this.$router.push('/step/confirm')
          } else {
            this.$toast(e.status.msg);
          }
        })
        .catch(() => {});
      this.$indicator.close();
      branch.logEvent('11_CONTINUED_LOAN_APPLY', { metadata: this.user.phone })
    },
    onServiceAgreement() {
      if (this.brand.channelInfo) {
        window.open(this.brand.channelInfo.privacyUrl)
      }
    },
    async getUser() {
      await this.$axios({
        method: "post",
        url: getUserInfo,
      }).then((e) => {
        if (e.status.code == "000") {
          this.SET_USER(e.body);
        }
      }).catch(() => {})
    },
  }
}
</script>

<style scoped lang="scss">
.home {
  position: relative;
  overflow: hidden;
  &::before {
    content: '';
    position: absolute;
    border-radius: 100%;
    background-color: #49B02D;
    width: 600px;
    transform: translateX(-50%);
    left: 50%;
    top: -320px;
    height: 500px;
    z-index: -1;
  }
  .titieIcon {
    font-size: 26px;
    margin-right: 8px;
    margin-left: 20px;
  }
  .header {
    display: flex;
    margin-left: 20px;
    margin-right: 20px;
    padding-top: 7px;
    padding-bottom: 7px;
    color: white;
    .val {
      flex: 1;
      min-height: 30px;
      img {
        width: 30px;
        vertical-align: middle;
      }
    }
  }
  .prompt {
    font-size: 24px;
    color: white;
    font-weight: bold;
    margin-left: 20px;
    margin-right: 20px;
  }
  .home_tips {
    padding-left: 20px;
    padding-right: 20px;
    color: white;
    font-size: 13px;
  }
  .loan {
    margin: 10px 50px;
    .box {
      position: relative;
      overflow: hidden;
      .val {
        position: absolute;
        width: 100%;
        height: 100%;
        .title {
          font-weight: bold;
          color: #010038;
          font-size: 15px;
          text-align: center;
          margin-top: 20px;
        }
        .tips {
          margin-top: 5px;
          font-size: 30px;
          text-align: center;
          font-weight: bold;
          color: #010038;
        }
      }
    }
    .bg {
      width: 100%;
      float: left;
    }
    .bottom {
      margin-bottom: 10px;
      .mint-button {
        color: #fff;
        height: 50px;
        font-size: 18px;
        background-color: #49B02D;
        border-radius: 10px;
        font-weight: bold;
      }
    }
    .link {
      padding: 10px 0;
      text-align: center;
      font-size: 14px;
      color: #010038;
      padding: 10px 0;
      span {
        color: #49B02D;
        font-weight: 600;
      }
    }
  }
  .not_settle {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 25px 20px 25px;
    background: white;
    border-radius: 5px;
    font-size: 14px;
    box-shadow: 0px 0px 10px 0px rgba(34, 35, 40, 0.1);
    img {
      width: 100px;
      margin: 15px;
    }
    .val {
      padding: 10px;
      flex: 1;
      color: #048130;
      div {
        margin-bottom: 10px;
      }
      div > span {
        color: red;
        font-weight: 700;
      }
      .more {
        color: white;
        background: #048130;
        padding: 5px 10px;
        border-radius: 10px;
      }
    }
  }
  .flag {
    position: relative;
    margin: 20px 20px 20px 20px;
    background-color: white;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 0px 10px 0px rgba(34, 35, 40, 0.1);
    img {
      width: 100px;
      margin: 10px 15px 10px 10px;
    }
    .val {
      padding: 10px;
      flex: 1;
      font-size: 20px;
      color: #048130;
      font-weight: 700;
      div > span {
        color: #FFD801;
      }
      > span {
        color: #869096;
        font-size: 13px;
      }
    }
  }
  .userRecomendation {
    padding: 10px 25px 20px 25px;
    font-size: 15px;
    font-weight: bold;
  }
  .inviteFriends {
    margin: 0px 20px 20px 20px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #812279;
    .val {
      flex: 1;
      text-align: center;
      span {
        font-size: 20px;
        font-weight: bold;
        color: white;
      }
      .btn {
        margin-top: 10px;
        padding: 5px 20px;
        background-color: #048130;
        border-radius: 4px;
        color: white;
        font-size: 12px;
        font-weight: bold;
        display: inline-block;
      }
    }
    img {
      width: 100px;
      margin: 10px 15px 10px 10px;
    }
  }
  .box {
    .step {
      &:nth-child(even) {
        background: #eff6f8;
      }
      & > .title {
        font-size: 18px;
        padding: 10px;
        text-align: center;
        color: #048130;
      }
      .item {
        padding: 15px 20px 15px 10px;
        display: flex;
        align-items: center;
        .icon {
          font-size: 30px;
          margin: 0px 10px;
        }
        .index {
          width: 23px;
          flex-shrink: 0;
          height: 23px;
          text-align: center;
          line-height: 23px;
          background: #048130;
          color: #FFFFFF;
          font-weight: bold;
          border-radius: 50%;
        }
        .des {
          padding: 0 10px;
          flex-grow: 1;
          div {
            &:last-child {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}
</style>
