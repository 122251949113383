<template>
  <div class="password">
    <mt-header :title="$t('password')">
      <router-link to slot="left">
        <mt-button @click="$router.back(-1)" icon="back">{{ $t('back') }}</mt-button>
      </router-link>
      <call-phone slot="right" />
    </mt-header>
    <div class="content">
      <div class="label">{{ $t('phone') }}</div>
      <mt-field
        :placeholder="$t('phonePlaceholder')"
        v-model="form.phone"
        type="tel"
        v-mask="'#### #### #### #'"
        :state="phoneState"
      ></mt-field>
      <div class="label">{{ $t('validateCode') }}</div>
      <mt-field v-model="form.validateCode" :state="validateCodeState">
        <mt-button type="primary" size="small" plain :disabled="!!time" @click="getSmsCode('MODIFY_PASSWORD')">{{
          $t("smsCode")
        }}{{ time? `(${time})`: '' }}</mt-button>
      </mt-field>
      <mt-cell>
        <span style="margin-right: 5px;">{{ $t("youCanTry") }}</span>
        <mt-button size="small" @click="getIvrCode('MODIFY_PASSWORD')" :disabled="!!time">{{ $t("getIvrCode") }}{{ time? `(${time})`: '' }}</mt-button>
      </mt-cell>
      <div class="label">{{ $t('idcard') }}</div>
      <mt-field
        :placeholder="$t('idcardPlaceholder')"
        type="number"
        v-model="form.idcard"
        :state="idcardState"
      ></mt-field>
      <div class="label">{{ $t('password') }}</div>
      <mt-field
        :placeholder="$t('passwordPlaceholder')"
        :type="!visible? 'password': 'text'"
        v-model="form.password"
        :state="passwordState"
      >
        <img v-if="!visible" class="visible" src="../../assets/notVisible.png" height="20px" @click="visible = true">
        <img v-else class="visible" src="../../assets/browse.png" height="20px" @click="visible = false">
      </mt-field>
      <div class="label">{{ $t('confirmPassword') }}</div>
      <mt-field
        :placeholder="$t('passwordPlaceholder')"
        :type="!newVisible? 'password': 'text'"
        v-model="form.comfirmPassword"
        :state="comfirmPasswordState"
      >
        <img v-if="!newVisible" class="visible" src="../../assets/notVisible.png" height="20px" @click="newVisible = true">
        <img v-else class="visible" src="../../assets/browse.png" height="20px" @click="newVisible = false">
      </mt-field>
      <mt-button type="primary" size="large" @click="submit">{{
        $t("confirmModification")
      }}</mt-button>
    </div>
  </div>
</template>

<script>
import callPhone from '@/components/callPhone.vue'
import { phoneRules, validateCodeRules, idcardRules, passwordRules, comfirmPasswordRules } from "../../utils/rules";
import { mapMutations } from "vuex";
import { password } from '../../utils/api'
import mixin from './mixin'
export default {
  name: 'Password',
  mixins: [mixin],
  components: {
    callPhone
  },
  data() {
    return {
      visible: false,
      newVisible: false,
    }
  },
  computed: {
    phoneState() {
      this.SET_RECORD_ITEM({
        key: "passwordPhone",
        value: this.form.phone,
      });
      return this.isCheck ? phoneRules(this.form.phone) : null;
    },
    validateCodeState() {
      return this.isCheck ? validateCodeRules(this.form.validateCode) : null;
    },
    idcardState() {
      this.SET_RECORD_ITEM({
        key: "passwordIdcard",
        value: this.form.idcard,
      });
      return this.isCheck ? idcardRules(this.form.idcard) : null;
    },
    passwordState() {
      this.SET_RECORD_ITEM({
        key: "passwordPassword",
        value: this.form.password,
      });
      return this.isCheck ? passwordRules(this.form.password) : null;
    },
    comfirmPasswordState() {
      this.SET_RECORD_ITEM({
        key: "comfirmPasswordPassword",
        value: this.form.comfirmPassword,
      });
      return this.isCheck ? comfirmPasswordRules(this.form.comfirmPassword, this.form.password) : null;
    }
  },
  watch: {
    
  },
  created() {
    this.form.phone = this.record.passwordPhone || "";
    this.form.idcard = this.record.passwordIdcard || "";
    this.form.password = this.record.passwordPassword || "";
    this.form.password = this.record.comfirmPasswordPassword || "";
  },
  methods: {
    ...mapMutations(["SET_RECORD_ITEM"]),  
    async submit() {
      this.isCheck = true;
      var error = this.validation(['phone', 'validateCode', 'idcard', 'password', 'comfirmPassword']);
      if (error) return this.$toast(error);
      this.$indicator.open({
        spinnerType: "fading-circle",
      });
      await this.$axios({
        method: "post",
        url: password,
        data: {
          phone: this.form.phone.replace(/\s/g, ""),
          password: this.form.password,
          idcard: this.form.idcard,
          comfirmPassword: this.form.comfirmPassword,
          validateCode: this.form.validateCode,
          isValidateCode: 'Y'
        },
      })
        .then(async (e) => {
          if (e.status.code == "000") {
            this.SET_RECORD_ITEM({
              key: "loginPhone",
              value: this.form.phone,
            });
            this.SET_RECORD_ITEM({
              key: "loginPassword",
              value: this.form.password,
            });
            this.$emit('input', 'login')
          } else {
            this.$toast(e.status.msg)
          }
        })
        .catch(() => {});
      this.$indicator.close();
    },
  }
}
</script>

<style lang="scss" scoped>
.password {
  min-height: 100vh;
  background: url(../../assets/login_bg.png) no-repeat;
  background-size: contain;
  background-color: #2ADA4F;
  .ivr_box {
    padding: 10px 0;
  }
  .visible {
    margin-left: 5px;
  }
  .content {
    margin-left: 15px;
    margin-right: 15px;
    background-color: rgba(255, 255, 255, 0.7);
    padding: 12px;
    border-radius: 10px;
    margin-top: 15px;
    position: relative;
    .label {
      color: #000029;
      font-size: 15px;
      padding: 10px 0px;
    }
    .mint-field {
      border-radius: 3px;
      /deep/ .mint-cell-wrapper {
        background-image: none;
      }
    }
    > .mint-button {
      margin-top: 15px;
    }
  }
}
</style>