<template>
  <div class="loanDetail">
    <mt-header :title="$t('loanDetail')">
      <router-link v-if="callBack" :to="callBack" slot="left">
        <mt-button icon="back">{{ $t('back') }}</mt-button>
      </router-link>
      <router-link v-else to slot="left">
        <mt-button @click="$router.back(-1)" icon="back">{{ $t('back') }}</mt-button>
      </router-link>
      <call-phone slot="right" />
    </mt-header>
    <div class="content">
      <mt-cell :title="$t('contractNo')">
        <div>{{ contractNo }}</div>
      </mt-cell>
      <mt-cell :title="$t('loanAmount')">
        <div>{{ item.applyAmount | formatMoney }}</div>
      </mt-cell>
      <mt-cell :title="$t('loanTerm')">
        <div>{{ item.displayLoanDays }} {{ $t('day') }}</div>
      </mt-cell>
      <mt-cell :title="$t('filingDate')">
        <div>{{ item.applyDate }}</div>
      </mt-cell>
      <mt-cell v-if="item.loanDate" :title="$t('paymentDate')">
        <div>{{ item.loanDate}}</div>
      </mt-cell>
      <mt-cell v-if="item.instalmentMark != 'Y' && item.repaymentDate" :title="$t('repaymentDate')">
        <div>{{ item.repayDate | formatDate('DD-MM-YYYY') }}</div>
      </mt-cell>
      <mt-cell v-if="item.serviceFees" :title="$t('serviceFee')">
        <div>{{ item.serviceFees | formatMoney }}</div>
      </mt-cell>
      <mt-cell v-if="item.totalRepayAmount" :title="$t('amountOfRepayment')">
        <div>{{ item.totalRepayAmount | formatMoney }}</div>
      </mt-cell>
      <mt-cell v-if="item.couponAmount" :title="$t('repaymentReliefReward')">
        <div>{{ item.couponAmount | formatMoney }}</div>
      </mt-cell>
      <mt-cell v-if="item.realRepayAmount" title="Remaining amount payable">
        <div>{{ item.realRepayAmount | formatMoney }}</div>
      </mt-cell>
      <mt-cell :title="$t('contractStatus')">
        <div>{{ item.contractStatusName }}</div>
      </mt-cell>
    </div>

    <div class="stages" v-if="item.instalmentMark == 'Y'">
      <div class="item" v-for="(item, index) in (trial.paymentSchedules || [])" :key="index">
        <div class="title">{{ $t('stage') }} {{ index + 1 }}/{{ trial.paymentSchedules.length }}</div>
        <div>
          <mt-cell :title="$t('repaymentDate')">
            {{ item.loanPmtDueDate }}
          </mt-cell>
          <mt-cell :title="$t('repaymentAmount')">
            <template v-if="item.freeMark == 'Y'">
              <s>{{ item.totalAmt | formatMoney }}</s>
              <div class="free">
                {{ $t('free') }}
              </div>
            </template>
            <template v-else>
              {{ item.totalAmt | formatMoney }}
            </template>
          </mt-cell>
        </div>
      </div>
    </div>

    <div v-if="item.contractStatus == 'REPAY' || item.contractStatus == 'NORMAL' || item.contractStatus == 'OVERDUE' " class="bottom">
      <mt-button type="primary" size="large" @click="$router.push(`/mine/repaymentInfo?applyId=${contractNo}`)">{{
        $t("repayment")
      }}</mt-button>
    </div>
  </div>
</template>

<script>
import callPhone from '@/components/callPhone.vue'
import { contractDetail } from '../../utils/api'
export default {
  name: 'LoanDetail',
  components: {
    callPhone
  },
  computed: {
    contractNo() {
      return this.$route.query.contractNo
    },
    callBack() {
      return this.$route.query.callBack
    }
  },
  data () {
    return {
      item: {}
    }
  },
  created() {
    this.init()
  },
  methods: {
    async init() {
      this.$indicator.open({
        spinnerType: "fading-circle",
      });
      await this.getContractDetail()
      this.$indicator.close();
    },
    async getContractDetail() {
      await this.$axios({
        method: 'post',
        url: contractDetail,
        params: {
          applyId: this.contractNo
        }
      })
        .then((e) => {
          if (e.status.code == '000') {
            this.item = e.body
          }
        })
        .catch(() => {})
    }
  }
}
</script>

<style lang="scss" scoped>
.loanDetail {
  min-height: 100vh;
  background: url(../../assets/page_bg.png);
  background-position: top center;
  background-size: cover;
  .detail {
    width: 16px;
    margin: 0 5px;
  }
  .mint-cell {
    background: rgba(255, 255, 255, 0.8);
    &:last-child {
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }
    &:first-child {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
  }
  .content {
    margin: 20px;
  }
  .stages {
    .item {
      box-shadow: 0px 0px 2px 0px rgba(34, 35, 40, 0.22);
      margin: 10px 20px;
      padding: 10px;
      border-radius: 5px;
      .title {
        font-weight: bold;
        padding-bottom: 10px;
        font-size: 18px;
      }
      /deep/ .mint-cell-value {
        color: brown;
        font-weight: 600;
      }
      .free {
        padding: 5px 10px;
        background: #F15234;
        color: white;
        border-radius: 5px;
        margin-left: 5px;
      }
    }
  }
  .bottom {
    padding: 20px 20px 20px 20px;
  }
}
</style>
