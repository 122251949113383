import { mapMutations } from "vuex";
import { phoneRules } from "../../utils/rules";
import { validationCode, getUserInfo } from '../../utils/api'
import { mapState } from "vuex";
let mixin = {
  components: {},
  data() {
    return {
      isValidateCode: 'Y',
      validateSkip: 'N',
      isCheck: false,
      timer: null,
      time: 0,
      form: {
        phone: "",
        password: "",
        idcard: "",
        comfirmPassword: "",
        validateCode: "",
      },
    }
  },
  computed: {
    ...mapState(["record", "brand"]),
  },
  created() {
    
  },
  methods: {
    ...mapMutations(["SET_USER"]),
    async getSmsCode(type) {
      let state = phoneRules(this.form.phone)
      if (state != "success") {
        return this.$toast(state == "warning"
          ? this.$t("phoneWarning")
          : this.$t("phoneError"));
      }
      if (this.time) return
      this.$toast(this.$t('getSmsTips'));
      this.time = this.brand.smsWaitInterval || 60
      this.$axios
        .post(validationCode, {
          phone: this.form.phone.replace(/\s/g, ''),
          type: type,
          sendChannel: 'SMS'
        })
        .then((e) => {
          if (e.status.code == "000") {
            this.timer = setInterval(() => {
              this.time--
              if (this.time <= 0) {
                this.time = 0
                if (e.body.validateSkip == 'Y') {
                  this.isValidateCode = 'N'
                }
                clearInterval(this.timer)
              }
            }, 1000)
          } else {
            this.time = 0
            this.$toast(e.status.msg);
          }
        })
        .catch(() => {
          this.time = 0
        })
    },
    getIvrCode(type) {
      let state = phoneRules(this.form.phone)
      if (state != "success") {
        return this.$toast(state == "warning"
          ? this.$t("phoneWarning")
          : this.$t("phoneError"));
      }
      if (this.time) return
      this.$toast(this.$t('getSmsTips'));
      this.time = this.brand.smsWaitInterval || 60
      this.$axios
        .post(validationCode, {
          phone: this.form.phone.replace(/\s/g, ''),
          type: type,
          sendChannel: 'IVR'
        })
        .then((e) => {
          if (e.status.code == "000") {
            this.timer = setInterval(() => {
              this.time--
              if (this.time <= 0) {
                this.time = 0
                if (e.body.validateSkip == 'Y') {
                  this.isValidateCode = 'N'
                }
                clearInterval(this.timer)
              }
            }, 1000)
          } else {
            this.time = 0
            this.$toast(e.status.msg);
          }
        })
        .catch(() => {
          this.time = 0
        })
    },
    async getUser() {
      await this.$axios({
        method: "post",
        url: getUserInfo,
      })
        .then((e) => {
          if (e.status.code == "000") {
            this.SET_USER(e.body);
            switch (e.body.applyStatus) {
              case "CANCEL":
              case "CONTINUED_LOAN_CANCEL":
              case "LOAN":
              case "REPAY":
                // 到订单详情
                this.$router.push(`/apply/loanDetail?contractNo=${e.body.applyId}&callBack=/`)
                break;
              case "APPROVE":
              case "REJECTED":
              case "CONTINUED_LOAN_REJECTED":
              case "SUPPLEMENTARY":
                // 到审核详情 applyId
                this.$router.push({path: '/apply/done'})
                break;
              // case "SUPPLEMENT_IMAGE":
              // case "SUPPLEMENT_BANK_CARD":
              // case "SUPPLEMENT_IMAGE_CARD":
              //   // 到补件
              //   this.$router.push({path: '/step/supplementary'})
              //   break;
              default:
                this.$router.push("/");
                break;
            }
          } else {
            this.$toast(e.status.msg);
          }
        })
        .catch(() => {});
    },
    validation(list) {
      if (list.indexOf('phone') >= 0 && this.phoneState != "success") {
        return this.phoneState == "warning"
          ? this.$t("phoneWarning")
          : this.$t("phoneError");
      }
      if (list.indexOf('validateCode') >= 0 && this.validateCodeState != "success") {
        return this.validateCodeState == "warning"
          ? this.$t('validateCodeWarning')
          : this.$t('validateCodeError');
      }
      if (list.indexOf('idcard') >= 0 && this.idcardState != "success") {
        return this.idcardState == "warning"
          ? this.$t('idcardPlaceholder')
          : this.$t('idcardError');
      }
      if (list.indexOf('password') >= 0 && this.passwordState != "success") {
        return this.passwordState == "warning"
          ? this.$t("passwordWarning")
          : this.$t("passwordError");
      }
      if (list.indexOf('comfirmPassword') >= 0 && this.comfirmPasswordState != "success") {
        return this.comfirmPasswordState == "warning"
          ? this.$t('comfirmPasswordWarning')
          : this.$t('comfirmPasswordError');
      }
    },
  }
}
export default mixin;